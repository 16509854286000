import React, { useState, useEffect, useRef } from 'react';
import { useTranslate, useGetList } from 'react-admin';
import { InfoWindow } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Map, { Marker } from '../common/Map';


const useStyles = makeStyles((theme) => ({
    boxUsage: {
        height: '550px',
        boxSizing: 'border-box'
    },
    tableUsage: {
        overflowY: 'auto',
        padding: '15px',
        paddingRight: 0
    },
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            borderRadius: '100px'
        }
    },
    cell: {
        border: 'none'
    }
}));

const UsageMarker = ({ data, ...props }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    const closeTooltip = () => {
        setTooltipOpen(false);
    };

    if (!data.lat || !data.lng) {
        return null;
    }

    return (
        <Marker
            position={{ lat: data.lat, lng: data.lng }}
            onClick={toggleTooltip}
            {...props}
        >
            {(tooltipOpen || props.tooltipOpen) && (
                <InfoWindow onCloseClick={closeTooltip}>
                    <>
                        <Typography color="primary"><b>{data.name}</b></Typography>
                        <Typography>{data.current_month_usage}GB</Typography>
                    </>
                </InfoWindow>
            )}
        </Marker>
    );
};

const SiteList = () => {
    const [currentSite, setCurrentSite] = useState();
    const { data: sites, ids: siteIds, isLoading: isLoadingSites, total: totalSites } = useGetList(
        'sites',
        { page: 1, perPage: 100 },
        { field: 'current_month_usage', order: 'DESC' }
    );
    const translate = useTranslate();
    const classes = useStyles();
    const mapRef = useRef(null);

    const handleMouseEnterSite = (site) => {
        setCurrentSite(site);
    };

    const handleMouseLeaveSite = () => {
        setCurrentSite(null);
    };

    useEffect(() => {
        const currentSites = Object.values(sites);
        if (mapRef.current && currentSites?.length > 0) {
            let needUpdate = false;
            const bounds = new window.google.maps.LatLngBounds();
            currentSites.forEach(site => {
                if (site.lat && site.lng) {
                    bounds.extend({ lat: site.lat, lng: site.lng });
                    needUpdate = true;
                }
            });
            if (needUpdate) {
                mapRef.current.fitBounds(bounds);
            }
        }
    }, [mapRef, sites]);

    if (isLoadingSites || totalSites <= 0) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Card>
                <Box padding={3}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" gutterBottom>{translate('Current Month Data Usage by Site')}</Typography>
                    </Box>
                    <Grid container>
                        <Grid item md={8} xs={12}>
                            <Box className={classes.boxUsage}>
                                <Map height="550px" ref={mapRef}>
                                    <MarkerClusterer>
                                        {Object.values(sites).map(site => (
                                            <UsageMarker key={`marker_${site.id}`} tooltipOpen={currentSite?.id === site.id} data={site} />
                                        ))}
                                    </MarkerClusterer>
                                </Map>
                            </Box>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box className={`${classes.boxUsage} ${classes.tableUsage}`}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{translate('Name')}</TableCell>
                                            <TableCell>{translate('Mobile')}</TableCell>
                                            <TableCell>{translate('Starlink')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {siteIds?.map(id => (
                                            <TableRow key={`site_${sites[id].id}`} className={classes.row} onMouseEnter={() => handleMouseEnterSite(sites[id])} onMouseLeave={handleMouseLeaveSite}>
                                                <TableCell className={classes.cell}>{sites[id].name}</TableCell>
                                                <TableCell className={classes.cell}>{sites[id].current_month_usage ? `${sites[id].current_month_usage}GB` : ''}</TableCell>
                                                <TableCell className={classes.cell}>{sites[id].starlink_current_month_usage ? `${sites[id].starlink_current_month_usage}GB` : ''}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Grid>
    );
};

export default SiteList;