import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';

const optionText = choice => choice.name ? `${choice.name} / ${choice.vat}`: '';

const SimPoolCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput source="name" />
			<TextInput source="customer_notes" label="Notes" options={{ multiline: true }} rows="4" />
		</SimpleForm>
	</Create>
);

export default SimPoolCreate;
