import React, { Fragment } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';


const styles = {
	listItem: {
		paddingLeft: '1rem',
		margin: '5px 10px',
		borderRadius: '200px',
		boxSizing: 'border-box',
		width: 'auto',
		overflow: 'hidden'
	},
	listItemText: {
		paddingLeft: 2
	},
	sidebarIsOpen: {
		paddingLeft: 25,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
	},
	sidebarIsClosed: {
		paddingLeft: 0,
		transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
	},
	iconArrow: {
		minWidth: '40px'
	}
};

const SubMenu = ({
	handleToggle,
	sidebarIsOpen,
	isOpen,
	name,
	icon,
	classes,
	children
}) => (
	<Fragment>
		<ListItem
			dense
			button
			onClick={handleToggle}
			className={classes.listItem}
		>
			<ListItemIcon className={classes.iconArrow}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
			<ListItemText
				inset
				primary={isOpen ? name : ''}
				secondary={isOpen ? '' : name}
				className={classes.listItemText}
				disableTypography
			/>
		</ListItem>
		<Collapse in={isOpen} timeout="auto" unmountOnExit>
			<List
				component="div"
				disablePadding
				className={
					sidebarIsOpen
						? classes.sidebarIsOpen
						: classes.sidebarIsClosed
				}
			>
				{children}
			</List>
		</Collapse>
	</Fragment>
);

SubMenu.defaultProps = {
	icon: <ArrowRightIcon />
};

export default withStyles(styles)(SubMenu);