import SimCard from '@material-ui/icons/SimCard';

import SimPoolList from './SimPoolList';
import SimPoolEdit from './SimPoolEdit';
import SimPoolCreate from './SimPoolCreate';
import SimPoolShow from './SimPoolShow';

export default {
	list: SimPoolList,
	edit: SimPoolEdit,
	create: SimPoolCreate,
	show: SimPoolShow,
	icon: SimCard
};
