import deMessages from 'ra-language-german';

const de = {
    ...deMessages,
    resources: {
        sites: {
            name: 'Standort |||| Standorte',
            fields: {
                name: 'Name',
                address: 'Adresse',
                updated_at: 'Aktualisiert am',
            }
        },
        simpools: {
            // name: ' |||| ',
            fields: {
                name: 'Name',
                invoicing: 'Rechnungsstellung',
                updated_at: 'Aktualisiert am',
                created_at: 'Erstellt am',
                description: 'Beschreibung',
                cards_count: 'Anzahl der Karten',
            }
        },
        simcards: {
            name: 'SIM-Karte |||| SIM-Karten',
            fields: {
                iccid: 'ICCID',
                provider_name: 'Anbietername',
                active: 'Aktiv',
            }
        },
        devices: {
            name: 'Gerät |||| Geräte',
            fields: {
                serial: 'Seriennummer',
                site: 'Standort',
                last_online: 'Zuletzt online',
                wans: 'WANS',
                name: 'Name',
                ip_address: 'IP-Adresse',
                updated_at: 'Aktualisiert am',
                online_status: 'Online-Status',
                address: 'Adresse',
                data_limit_contacts: 'Datenlimit-Kontakte',
                created_at: 'Erstellt am',
            }
        },
        lines: {
            name: 'Leitung |||| Leitungen',
            fields: {
                subscription_id: 'Abonnement-ID',
                provider_name: 'Anbietername',
                status: 'Status',
                line_type: 'Leitungstyp',
            }
        },
        orders: {
            name: 'Bestellung |||| Bestellungen',
            fields: {
                reference: 'Referenz',
                po_reference: 'Bestellreferenz',
                customer_name: 'Kundenname',
                data_region: 'Datenregion',
                number: 'Nummer',
            }
        },
        invoices: {
            name: 'Rechnung |||| Rechnungen',
            fields: {
                invoice_type: 'Rechnungstyp',
                invoiced_at: 'In Rechnung gestellt am',
                total_untaxed: 'Gesamtbetrag ohne Steuern',
                start_date: 'Startdatum',
                end_date: 'Enddatum',
            }
        },
        'usages/report': {
            name: 'Nutzungsbericht |||| Nutzungsberichte',
            fields: {
                site: 'Standort',
                pool: 'Pool',
                device: 'Gerät',
                iccid: 'ICCID',
                start_date: 'Startdatum',
                end_date: 'Enddatum',
                group_by: 'Gruppieren nach',
            },
        },
        data_pricings: {
            name: 'Datenpreisgestaltung |||| Datenpreisgestaltungen',
            fields: {
                provider: 'Anbieter',
                country_code: 'Ländercode',
                imsi: 'IMSI',
            },
        },
        location_updates: {
            // name: ' |||| ',
            fields: {
                timestamp: 'Zeitstempel',
                iccid: 'ICCID',
                type: 'Typ',
                mcc: 'MCC',
                mnc: 'MNC',
                network_name: 'Netzwerkname',
                imsi: 'IMSI',
                imsi_id: 'IMSI-ID',
            },
        },
        starlink_terminals: {
            name: 'Starlink-Terminal |||| Starlink-Terminals',
            fields: {
                terminal_id: 'Terminal-ID',
                kit_serial_number: 'KIT-Seriennummer',
                dish_serial_number: 'Antenne-Seriennummer',
                alert_install_pending: 'Warnung: Installation ausstehend',
                alert_is_heating: 'Warnung: Heizung aktiv',
                alert_mast_not_near_vertical: 'Warnung: Mast nicht senkrecht',
                alert_motors_stuck: 'Warnung: Motoren blockiert',
                alert_roaming: 'Warnung: Roaming aktiv',
                alert_slow_ethernet_speeds: 'Warnung: Langsame Ethernet-Geschwindigkeit',
                alert_thermal_shutdown: 'Warnung: Thermische Abschaltung',
                alert_unexpected_location: 'Warnung: Unerwarteter Standort',
            }
        },
        'starlink_usages/report': {
            name: 'Starlink-Nutzungsbericht |||| Starlink-Nutzungsberichte',
            fields: {
                group_by: 'Gruppieren nach',
                start_date: 'Startdatum',
                end_date: 'Enddatum',
            }
        },
        packages: {
            name: 'Paket |||| Pakete',
            fields: {
                customer_reference: 'Kundenreferenz',
                invoice_regroupment_reference: 'Rechnungsbündelungsreferenz',
                start_date: 'Startdatum',
                end_date: 'Enddatum',
            }
        },
        users: {
            name: 'Benutzer |||| Benutzer',
            fields: {
                email: 'E-Mail',
                firstname: 'Vorname',
                lastname: 'Nachname',
                password: 'Passwort',
                is_manager: 'Ist Manager',
                can_order: 'Kann Bestellungen aufgeben',
                can_manage_users: 'Kann Benutzer verwalten',
            },
        },
    },
    'Search': 'Suche',
    'Account': 'Konto',
    'Support': 'Support',
    'summary': 'Zusammenfassung',
    'Name': 'Name',
    'Devices': 'Geräte',
    'Device': 'Gerät',
    'Site': 'Standort',
    'Simcard': 'SIM-Karte',
    'ICCID': 'ICCID',
    'Wans': 'WANS',
    'Data Usage monthly (GB)': 'Datenverbrauch monatlich (GB)',
    'Data Usage last 30 days (GB)': 'Datenverbrauch letzte 30 Tage (GB)',
    'Create ticket': 'Ticket erstellen',
    'Move to sim pool': 'In den SIM-Pool verschieben',
    'Are you sure to move the simcard to the SIM pool?': 'Möchten Sie die SIM-Karte wirklich in den SIM-Pool verschieben?',
    'Pool': 'Pool',
    'Current Month Usage (GB)': 'Aktueller Monatsverbrauch (GB)',
    'Sync': 'Synchronisieren',
    'Device synchronized': 'Gerät synchronisiert',
    'The device is now rebooting': 'Das Gerät wird jetzt neu gestartet',
    'Reboot failed': 'Neustart fehlgeschlagen',
    'Reboot': 'Neustart',
    'Reboot device': 'Gerät neu starten',
    'Do you want to reboot the device': 'Möchten Sie das Gerät neu starten?',
    'Status': 'Status',
    'Network type': 'Netzwerktyp',
    'Online at': 'Online seit',
    'Product': 'Produkt',
    'Product name': 'Produktname',
    'Data Limit Alert (GB)': 'Datenlimit-Warnung (GB)',
    'Create an order': 'Bestellung erstellen',
    'Quote Request Only': 'Nur Angebotsanfrage',
    'Customer name': 'Kundenname',
    'Data bundle': 'Datenpaket',
    'Bundle': 'Paket',
    'Overconsumption': 'Überkonsum',
    'Fixed IP': 'Feste IP',
    'Expected Start Date': 'Erwartetes Startdatum',
    'Duration in months (min 12)': 'Dauer in Monaten (mindestens 12)',
    'Expected bitrate Down': 'Erwartete Bitrate Down',
    'Expected bitrate Up': 'Erwartete Bitrate Up',
    'Site Address': 'Standortadresse',
    'Street': 'Straße',
    'Number': 'Nummer',
    'Zip Code': 'Postleitzahl',
    'City': 'Stadt',
    'Country': 'Land',
    'Latitude': 'Breitengrad',
    'Longitude': 'Längengrad',
    'Remarks (ordinary building, circus, ...)': 'Bemerkungen (normales Gebäude, Zirkus, ...)',
    'Contact Person': 'Kontaktperson',
    'Site Contact Firstname': 'Vorname des Standortkontakts',
    'Site Contact Lastname': 'Nachname des Standortkontakts',
    'Site Contact Email': 'E-Mail des Standortkontakts',
    'Site Contact Phone': 'Telefon des Standortkontakts',
    'Shipping': 'Versand',
    'VAT Number': 'Umsatzsteuer-Identifikationsnummer',
    'Invoice email address': 'Rechnungs-E-Mail-Adresse',
    'xDSL Line': 'xDSL-Leitung',
    'Add a Fixed Line': 'Feste Leitung hinzufügen',
    'Line Technology': 'Leitungstechnologie',
    'Order created': 'Bestellung erstellt',
    'Send': 'Senden',
    'Send order': 'Bestellung senden',
    'Error when sending order': 'Fehler beim Senden der Bestellung',
    'Invoicing': 'Rechnungsstellung',
    'Order sended': 'Bestellung gesendet',
    'INTRA GOODS 0%': 'INTRA WAREN 0%',
    'INTRA SERVICES 0%': 'INTRA DIENSTLEISTUNGEN 0%',
    'EXPORT GOODS 0%': 'EXPORT WAREN 0%',
    'EXPORT SERVICES 0%': 'EXPORT DIENSTLEISTUNGEN 0%',
    'NA': 'NA',
    'SEAGOING': 'SEEGEHEND',
    'Export data SIM only': 'Exportdaten nur SIM',
    'Total': 'Gesamt',
    'Provider': 'Anbieter',
    'Network': 'Netzwerk',
    'Price/MB': 'Preis/MB',
    'Usage reports': 'Nutzungsberichte',
    'Usage Reports': 'Nutzungsberichte',
    'Data pricing': 'Datenpreise',
    'Map': 'Karte',
    'Network events': 'Netzwerkereignisse',
    'Create order': 'Bestellung erstellen',
    'Result code': 'Ergebniscode',
    'Access at': 'Zugriff auf',
    'Forgot password': 'Passwort vergessen',
    'An email has been sent': 'Eine E-Mail wurde gesendet',
    'Reset password': 'Passwort zurücksetzen',
    'Ticket created': 'Ticket erstellt',
    'Create a ticket': 'Ticket erstellen',
    'Forgot password?': 'Passwort vergessen?',
    'Archived': 'Archiviert',
    'Notes': 'Notizen',
    'Manager': 'Manager',
    'Description': 'Beschreibung',
    'Welcome to the %{name} portal': 'Willkommen im %{name} Portal',
    'This month': 'Diesen Monat',
    'Last month': 'Letzten Monat',
    'Last 6 months': 'Letzte 6 Monate',
    'Last 12 months': 'Letzte 12 Monate',
    'ICCID in Pool': 'ICCID im Pool',
    'ICCID in Device': 'ICCID im Gerät',
    'Current Month Data Usage by Site': 'Datenverbrauch des aktuellen Monats nach Standort',
    'Current Month Data Usage by Simpool': 'Datenverbrauch des aktuellen Monats nach Simpool',
    'SIM Management': 'SIM-Management',
    'Suspend SIM': 'SIM deaktivieren',
    'Enable SIM': 'SIM aktivieren',
    'Are you sure you want to enable the SIM %{iccid}?': 'Sind Sie sicher, dass Sie die SIM %{iccid} aktivieren möchten?',
    'Are you sure you want to suspend the SIM %{iccid}?': 'Sind Sie sicher, dass Sie die SIM %{iccid} deaktivieren möchten?',
    'SIM suspended': 'SIM deaktiviert',
    'SIM enabled': 'SIM aktiviert',
    'Amount due': 'Fälliger Betrag',
    'Amount over due': 'Überfälliger Betrag',
    'Daily': 'Täglich',
    'Monthly': 'Monatlich',
    'Yearly': 'Jährlich',
    'Mobile': 'Mobil',
    'Usage': 'Nutzung',
    'Mobile Current Month Usage(GB)': 'Mobilnutzung aktueller Monat (GB)',
    'StarLink Current Month Usage(GB)': 'StarLink-Nutzung aktueller Monat (GB)',
    'Serial': 'Seriennummer',
    'Simpools': 'Simpools',
    'Telemetry': 'Telemetrie',
    'OOB': 'OOB',
    'Terminal ID': 'Terminal-ID',
    'Antenna status': 'Antennenstatus',
    'Downlink speed': 'Download-Geschwindigkeit',
    'Uplink speed': 'Upload-Geschwindigkeit',
    'Latency': 'Latenz',
    'Uptime': 'Betriebszeit',
    'State from OOBM': 'Status von OOBM',
    'Software update state': 'Software-Update-Status',
    'Unobstructed ratio': 'Ungehinderte Verhältnis',
    'Antenna obstruction': 'Antennenbehinderung',
    'Network statistics': 'Netzwerkstatistiken',
    '15m': '15 Minuten',
    '1 hr': '1 Stunde',
    '24 hrs': '24 Stunden',
    '7 days': '7 Tage',
    '30 days': '30 Tage',
    'Downlink throughput': 'Downlink-Durchsatz',
    'Uplink throughput': 'Uplink-Durchsatz',
    'Ping drop rate': 'Ping-Verlustquote',
    'Ping drop': 'Ping-Verlust',
    'Obstruction': 'Behinderung',
    'Stats export': 'Statistikexport',
    'Never': 'Nie',
    'Data Usage': 'Datenverbrauch',
    'Settings': 'Einstellungen',
    'Firstname': 'Vorname',
    'Lastname': 'Nachname',
    'Password': 'Passwort',
    'KIT Serial': 'KIT-Seriennummer',
    'Package Reference': 'Paketreferenz',
    'Customer Reference': 'Kundenreferenz',
    'Services': 'Dienstleistungen',
    'Line': 'Leitung',
    'Custom': 'Benutzerdefiniert',
    'Package ID Reference': 'Paket-ID-Referenz',
    'Draft': 'Entwurf',
    'Provisioning': 'Bereitstellung',
    'Deployed': 'Bereitgestellt',
    'Paused': 'Pausiert',
    'Ended': 'Beendet',
    'Shipping Address': 'Lieferadresse',
    'Shipping Contact': 'Lieferkontakt',
    'Sold Hardware': 'Verkaufte Hardware',
    'DRAFT': 'ENTWURF',
    'PROVISIONING': 'BEREITSTELLUNG',
    'DELIVERED': 'GELIEFERT',
    'ENDING': 'ENDET',
    'ENDED': 'BEENDET',
    'Running': 'Läuft',
    'LINE': 'LEITUNG',
    'Price': 'Preis',
    'Billing address': 'Rechnungsadresse',
    'Starlink Usage reports': 'Starlink-Nutzungsberichte',
    '%{length} of %{total} devices': '%{length} von %{total} Geräten'
};

export default de;