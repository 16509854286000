import RouterIcon from '@material-ui/icons/Router';

import PackageList from './PackageList';
import PackageShow from './PackageShow';


export default {
	list: PackageList,
	show: PackageShow,
	icon: RouterIcon,
	title: 'Packages',
};
