import React from 'react';
import { TabbedShowLayout, Tab, TextField, useTranslate } from 'react-admin';
import { Helmet } from 'react-helmet';

import Show from '../common/Show';
import DateField from '../common/DateField';
import CustomList from '../common/CustomList';
import SimCardRow from '../simcards/SimCardRow';


const SimCardList = props => (
	<CustomList
		{...props}
		title=" "
		hasCreate={false}
		perPage={25}
		filter={{ pool_id: props.record.id }}
		basePath="/simcards"
		resource="simcards"
		bulkActionButtons={false}
	>
		<SimCardRow exclude={['simpool']} />
	</CustomList>
);

const SimPoolTitle = ({ record }) => {
	const translate = useTranslate();
	const title = translate('resources.simpools.name', { smart_count: 1, _: 'Simpool' });

	return (
		<>
			<Helmet>
				<title>{`KB - ${title}[${record.name}]`}</title>
			</Helmet>
			<span>{record.name}</span>
		</>
	);
};

const SimPoolShow = props => (
	<Show title={<SimPoolTitle />} {...props}>
		<TabbedShowLayout>
			<Tab label="summary">
				<TextField source="name" />
				<TextField source="customer_notes" label="Notes" />
				<DateField source="created_at" />
				<DateField source="updated_at" />
				<SimCardList />
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default SimPoolShow;
