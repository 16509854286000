import React from 'react';
import { TextInput } from 'react-admin';
// import Box from '@material-ui/core/Box';

import List, { ListActions } from '../common/List';
import Filter from '../common/Filter';
import PackageRow from './PackageRow';
// import ContactBox from './ContactBox';

const PackageActions = (props) => (
    <ListActions {...props}>
    </ListActions>
);

const PackageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const filterDefaultValues = {
    archived: false,
}

const PackageList = props => (
    <>
        <List {...props} filters={<PackageFilter />} actions={<PackageActions />} filterDefaultValues={filterDefaultValues} perPage={50} exporter={false}>
            <PackageRow />
        </List>
        {/* <Box mt={3}>
            <ContactBox />
        </Box> */}
    </>
);

export default PackageList;