import React from 'react';
import { TextField, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import DateField from '../common/fields/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import SplitButton from '../common/SplitButton';
import ChipField from '../common/fields/ChipField';
import ExportPDFButton from './InvoiceActionsButtons/ExportPDFButton';
import NumberCurrencyField from '../common/fields/NumberCurrencyField';
import StatusField from './fields/StatusField';

const InvoiceSplitButton = ({ record }) => (
    <SplitButton>
        <ShowButton record={record} label='' />
        <ExportPDFButton record={record} />
    </SplitButton>
);

const InvoiceRow = ({ children, ...props }) => (
    <Datagrid {...props}>
        <LinkFieldButton label="Number" sortBy="number">
            <TextField source="number" />
        </LinkFieldButton>
        <ChipField source="invoice_type" />
        <DateField source="invoiced_at" />
        <StatusField />
        <NumberCurrencyField source="total_untaxed" />
        <InvoiceSplitButton />
        {children}
    </Datagrid>
);

export default InvoiceRow;