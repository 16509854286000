import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'inline-block',
        alignItems: 'center',
        backgroundColor: fade(theme.palette.primary.main, 0.1),
        borderRadius: '100px',
        padding: '5px'
    },
    btn: {
        color: '#9291A5',
        padding: '11px 15px',
        border: 'none',
        borderRadius: '100px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontSize: '16px'
    },
    selectedBtn: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',

    }
}));

const ToggleButtonGroup = ({ children, value, setValue }) => {
    const classes = useStyles();

    return (
        <div className={classes.btnGroup}>
            {children?.map((child, index) => (
                React.cloneElement(child, { className: child?.props?.value === value ? `${classes.btn} ${classes.selectedBtn}` : classes.btn, onClick: () => setValue(child.props.value), key: `btn_group_${child?.props?.value}_${index}` })
            ))}
        </div>
    );
};

const ToggleButton = (props) => (
    <button {...props} />
);

export default ToggleButtonGroup;
export { ToggleButton };