// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router';
import { Helmet } from 'react-helmet';

import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import AppLayout from './components/AppLayout';

import providers from './components/providers';
import simcards from './components/simcards';
import simpools from './components/simpools';
import sites from './components/sites';
import addresses from './components/addresses';
import devices from './components/devices';
import lines from './components/lines';
import users from './components/users';
import location_updates from './components/location_updates';
import data_pricings from './components/data_pricings';
import invoices from './components/invoices';
import starlink_terminals from './components/starlink_terminals';
import packages from './components/packages';
import services from './components/services';
import package_lines from './components/package_lines';

import UsageReportList from './components/usages/UsageReportList';
import StarlinkUsageReportList from './components/starlink_usages/StarlinkUsageReportList';
import TicketCreate from './components/tickets/TicketCreate';
import Account from './components/Account';
import MapDeviceList from './components/devices/MapDeviceList';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import Impersonate from './components/Impersonate';

import getTheme from './style/theme';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import i18nProvider from './i18n/i18nProvider';


const customRoutes = [
    <Route path="/usages/reports" component={UsageReportList} />,
    <Route path="/starlink_usages/reports" component={StarlinkUsageReportList} />,
    <Route path="/tickets/new/:site_id" component={TicketCreate} />,
    <Route path="/tickets/new" component={TicketCreate} />,
    <Route path="/account" component={Account} />,
    <Route path="/devices/map" component={MapDeviceList} />,
    <Route exact path="/forgot-password" noLayout component={ForgotPasswordPage} />,
    <Route exact path="/reset_password/:token" noLayout component={ResetPasswordPage} />,
    <Route exact path="/impersonate/:token" noLayout component={Impersonate} />
];

const App = () => (
    <Admin
        title="Kenobi"
        layout={AppLayout}
        dashboard={Dashboard}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        theme={getTheme()}
        i18nProvider={i18nProvider}
        disableTelemetry
    >
        {permissions => [
            <Helmet><title>Venn - Kenobi</title></Helmet>,
            <Resource name="simcards" {...simcards} />,
            <Resource name="simpools" {...simpools} />,
            <Resource name="providers" {...providers} />,
            <Resource name="sites" {...sites} />,
            <Resource name="addresses" {...addresses} />,
            <Resource name="usages/report" />,
            <Resource name="starlink_usages/report" />,
            <Resource name="lines" {...lines} />,
            <Resource name="devices" {...devices} />,
            <Resource name="location_updates" {...location_updates} />,
            <Resource name="device_products" />,
            <Resource name="data_pricings" {...data_pricings} />,
            <Resource name="starlink_usages" />,
            permissions && permissions.includes('can_manage_users')
                ? <Resource name="users" {...users} /> : null,
            permissions && permissions.includes('can_view_invoices')
                ? <Resource name="invoices" {...invoices} /> : null,
            permissions && permissions.includes('can_view_starlinks')
                ? <Resource name="starlink_terminals" {...starlink_terminals} /> : null,
            permissions && permissions.includes('can_view_packages')
                ? <Resource name="packages" {...packages} /> : null,
            permissions && permissions.includes('can_view_packages')
                ? <Resource name="services" {...services} /> : null,
            permissions && permissions.includes('can_view_packages')
                ? <Resource name="package_lines" {...package_lines} /> : null,
        ]}
    </Admin>
);

export default App;
