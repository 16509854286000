import React from 'react';
import { ChipField as RAChipField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const chipStyle = {
	height: '20px',
	'& span': {
		fontSize: '12px',
		height: '12px'
	}
};

const useStyles = makeStyles({
	root: ({ color }) => {
		if(color) {
			return {
				...chipStyle,
				backgroundColor: color
			};
		}

		return { ...chipStyle };
	}
});

const ChipField = ({ color, ...props }) => {
	const classes = useStyles({ color });

	if (!props.record[props.source]) {
		return null;
	}

	return (
		<RAChipField className={classes.root} {...props} />
	);
};

export default ChipField;
