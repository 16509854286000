import React from 'react';
import { TextField, NumberField } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import LinkFieldButton from '../../common/LinkFieldButton';
import NumberCurrencyField from '../../common/fields/NumberCurrencyField';
import StatusField from './StatusField';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        boxShadow: 'none',
        border: '1px solid #F1F5F6',
        borderRadius: '12px'
    },
    title: {
        '&, & a, & a span': {
            color: '#000',
            fontWeight: 400,
            fontSize: '26px'
        }
    },
    text: {
        fontWeight: 400,
        fontSize: '14px',
        '& a': {
            color: '#000',
            textDecoration: 'underline'
        }
    },
    textContainer: {
        marginTop: '15px',
        marginBottom: '15px'
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    }
}));

const PackageLineCard = ({ record }) => {
    const classes = useStyles();

    return (
            <Paper className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.details}>
                        <Typography variant="body2" className={classes.text}>

                        </Typography>
                        <Typography className={classes.title} variant="h5">
                            <LinkFieldButton label="Device product" source="device_product" basePath="/device_products" record={record}>
                                <TextField source="reference" />
                            </LinkFieldButton>
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            Price: <NumberCurrencyField source="price" record={record} /> - Quantity: <NumberField source="qty" record={record} />
                        </Typography>
                        <Typography variant="body2" className={classes.text}>
                            Total: <NumberCurrencyField source="total" record={record} />
                        </Typography>
                        {(record.serials) && (
                            <div className={classes.textContainer}>
                                {record.serials && (
                                    <Typography variant="body2" className={classes.text}>
                                        Serials: <TextField source="serials" record={record} />
                                    </Typography>
                                )}
                            </div>
                        )}
                        <div className={classes.footer}>
                            <Typography variant="body2" className={classes.text}>
                                <StatusField record={record} />
                            </Typography>
                        </div>
                    </div>
                </div>
            </Paper>
    );
};

export default PackageLineCard;
