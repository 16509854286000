import frMessages from 'ra-language-french';

const fr = {
    ...frMessages,
    resources: {
        sites: {
            name: 'Site |||| Sites',
            fields: {
                name: 'Nom',
                address: 'Adresse',
                updated_at: 'Mis à jour le',
            }
        },
        simpools: {
            name: 'Pool de SIM |||| Pools de SIM',
            fields: {
                name: 'Nom',
                invoicing: 'Facturation',
                updated_at: 'Mis à jour le',
                created_at: 'Créé le',
                description: 'Description',
                cards_count: 'Nombre de cartes',
            }
        },
        simcards: {
            name: 'Carte SIM |||| Cartes SIM',
            fields: {
                iccid: null,
                provider_name: 'Nom du fournisseur',
                active: 'Actif'
            }
        },
        devices: {
            name: 'Appareil |||| Appareils',
            fields: {
                serial: 'Numéro de série',
                site: null,
                last_online: 'Dernière mise en ligne',
                wans: null,
                name: 'Nom',
                ip_address: 'Adresse IP',
                updated_at: 'Mis à jour le',
                online_status: 'Statut en ligne',
                address: 'Adresse',
                data_limit_contacts: 'Limite de données contacts',
                created_at: 'Créé le'
            }
        },
        lines: {
            name: 'Ligne |||| Lignes',
            fields: {
                subscription_id: 'Abonnement',
                provider_name: 'Nom du fournisseur',
                status: 'Statut',
                line_type: 'Type de ligne'
            }
        },
        orders: {
            name: 'Commande |||| Commandes',
            fields: {
                reference: 'Référence',
                po_reference: 'Référence du bon de commande',
                customer_name: 'Nom du client',
                data_region: 'Région de données',
                number: 'Numéro',
            }
        },
        invoices: {
            name: 'Facture |||| Factures',
            fields: {
                invoice_type: 'Type de facture',
                invoiced_at: 'Facturé le',
                total_untaxed: 'Total non taxé',
                start_date: 'Date de début',
                end_date: 'Date de fin',
            }
        },
        'usages/report': {
            name: 'Rapport d\'utilisation |||| Rapports d\'utilisation',
            fields: {
                site: null,
                pool: null,
                device: 'Appareil',
                iccid: null,
                start_date: 'Date de début',
                end_date: 'Date de fin',
                group_by: 'Grouper par',
            },
        },
        data_pricings: {
            name: 'Tarification des données |||| Tarifications des données',
            fields: {
                provider: 'Fournisseur',
                country_code: 'Code pays',
                imsi: null,
            },
        },
        location_updates: {
            // name: ' |||| ',
            fields: {
                timestamp: null,
                iccid: null,
                type: null,
                mcc: null,
                mnc: null,
                network_name: 'Nom du réseau',
                imsi: null,
                imsi_id: null
            },
        },
        starlink_terminals: {
            name: 'Terminal Starlink |||| Terminaux Starlink',
            fields: {
                terminal_id: 'ID du terminal',
                kit_serial_number: 'Numéro de série KIT',
                dish_serial_number: 'Numéro de série de l\'antenne',
                alert_install_pending: 'Alerte installation en attente',
                alert_is_heating: 'Alerte chauffe',
                alert_mast_not_near_vertical: 'alerte mât pas proche de la verticale',
                alert_motors_stuck: 'Alerte moteurs bloqués',
                alert_roaming: 'Alerte itinérance',
                alert_slow_ethernet_speeds: 'Alerte vitesses Ethernet lentes',
                alert_thermal_shutdown: 'Alerte arrêt thermique',
                alert_unexpected_location: 'Alerte emplacement inattendu'
            }
        },
        'starlink_usages/report': {
            name: 'Rapport d\'utilisation Starlink |||| Rapports d\'utilisation Starlink ',
            fields: {
                group_by: 'Grouper par',
                start_date: 'Date de début',
                end_date: 'Date de fin',
            }
        },
        packages: {
            // name: ' |||| ',
            fields: {
                customer_reference: 'Référence client',
                invoice_regroupment_reference: 'Référence de regroupement de factures',
                start_date: 'Date de début',
                end_date: 'Date de fin'
            }
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                email: null,
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe',
                is_manager: 'Est manager',
                can_order: 'Peut commander',
                can_manage_users: 'Peut gérer les utilisateurs'
            },
        },
    },
    'Search': 'Recherche',
    'Account': 'Compte',
    'Support': null,
    'summary': 'Résumé',
    'Name': 'Nom',
    'Devices': 'Appareils',
    'Device': 'Appareil',
    'Site': null,
    'Simcard': 'Carte SIM',
    'ICCID': null,
    'Wans': null,
    'Data Usage monthly (GB)': 'Utilisation mensuelle des données (GB)',
    'Data Usage last 30 days (GB)': 'Utilisation des données des 30 derniers jours (GB)',
    'Create ticket': 'Créer un ticket',
    'Move to sim pool': 'Déplacer vers le pool de SIM',
    'Are you sure to move the simcard to the SIM pool?': 'Êtes-vous sûr de vouloir déplacer la carte SIM vers le pool de SIM ?',
    'Pool': null,
    'Current Month Usage (GB)': 'Utilisation du mois en cours (GB)',
    'Sync': 'Synchroniser',
    'Device synchronized': 'Appareil synchronisé',
    'The device is now rebooting': 'L\'appareil redémarre maintenant',
    'Reboot failed': 'Échec du redémarrage',
    'Reboot': 'Redémarrer',
    'Reboot device': 'Redémarrer l\'appareil',
    'Do you want to reboot the device': 'Voulez-vous redémarrer l\'appareil',
    'Status': 'Statut',
    'Network type': 'Type de réseau',
    'Online at': 'En ligne à',
    'Product': 'Produit',
    'Product name': 'Nom du produit',
    'Data Limit Alert (GB)': 'Alerte de limite de données (GB)',
    'Create an order': 'Créer une commande',
    'Quote Request Only': 'Demande de devis uniquement',
    'Customer name': 'Nom du client',
    'Data bundle': 'Forfait de données',
    'Bundle': 'Forfait',
    'Overconsumption': 'Surconsommation',
    'Fixed IP': 'IP fixe',
    'Expected Start Date': 'Date de début prévue',
    'Duration in months (min 12)': 'Durée en mois (min 12)',
    'Expected bitrate Down': 'Débit descendant prévu',
    'Expected bitrate Up': 'Débit montant prévu',
    'Site Address': 'Adresse du site',
    'Street': 'Rue',
    'Number': 'Numéro',
    'Zip Code': 'Code postal',
    'City': 'Ville',
    'Country': 'Pays',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',
    'Remarks (ordinary building, circus, ...)': 'Remarques (bâtiment ordinaire, cirque...)',
    'Contact Person': 'Personne de contact',
    'Site Contact Firstname': 'Prénom du contact sur site',
    'Site Contact Lastname': 'Nom du contact sur site',
    'Site Contact Email': 'Email du contact sur site',
    'Site Contact Phone': 'Téléphone du contact sur site',
    'Shipping': 'Expédition',
    'VAT Number': 'Numéro de TVA',
    'Invoice email address': 'Adresse email de facturation',
    'xDSL Line': 'Ligne xDSL',
    'Add a Fixed Line': 'Ajouter une ligne fixe',
    'Line Technology': 'Technologie de ligne',
    'Order created': 'Commande créée',
    'Send': 'Envoyer',
    'Send order': 'Envoyer la commande',
    'Error when sending order': 'Erreur lors de l\'envoi de la commande',
    'Invoicing': 'Facturation',
    'Order sended': 'Commande envoyée',
    'INTRA GOODS 0%': 'MARCHANDISES INTRA 0%',
    'INTRA SERVICES 0%': 'SERVICES INTRA 0%',
    'EXPORT GOODS 0%': 'MARCHANDISES EXPORT 0%',
    'EXPORT SERVICES 0%': 'SERVICES EXPORT 0%',
    'NA': null,
    'SEAGOING': 'MARITIME',
    'Export data SIM only': 'Exporter les données SIM uniquement',
    'Total': null,
    'Provider': 'Fournisseur',
    'Network': 'Réseau',
    'Price/MB': 'Prix/MB',
    'Usage reports': 'Rapports d\'utilisation',
    'Usage Reports': 'Rapports d\'utilisation',
    'Data pricing': 'Tarification des données',
    'Map': 'Carte',
    'Network events': 'Événements réseau',
    'Create order': 'Créer une commande',
    'Result code': 'Code de résultat',
    'Access at': 'Accès le',
    'Forgot password': 'Mot de passe oublié',
    'An email has been sent': 'Un email a été envoyé',
    'Reset password': 'Réinitialiser le mot de passe',
    'Ticket created': 'Ticket créé',
    'Create a ticket': 'Créer un ticket',
    'Forgot password?': 'Mot de passe oublié ?',
    'Archived': 'Archivé',
    'Notes': null,
    'Manager': null,
    'Description': null,
    'Welcome to the %{name} portal': 'Bienvenue sur le portail %{name}',
    'This month': 'Ce mois-ci',
    'Last month': 'Le mois dernier',
    'Last 6 months': 'Les 6 derniers mois',
    'Last 12 months': 'Les 12 derniers mois',
    'ICCID in Pool': 'ICCID dans le pool',
    'ICCID in Device': 'ICCID dans l\'appareil',
    'Current Month Data Usage by Site': 'Utilisation des données du mois en cours par site',
    'Current Month Data Usage by Simpool': 'Utilisation des données du mois en cours par pool de SIM',
    'SIM Management': 'Gestion des SIM',
    'Suspend SIM': 'Suspendre la SIM',
    'Enable SIM': 'Activer la SIM',
    'Are you sure you want to enable the SIM %{iccid}?': 'Êtes-vous sûr de vouloir activer la SIM %{iccid} ?',
    'Are you sure you want to suspend the SIM %{iccid}?': 'Êtes-vous sûr de vouloir suspendre la SIM %{iccid} ?',
    'SIM suspended': 'SIM suspendue',
    'SIM enabled': 'SIM activée',
    'Amount due': 'Montant dû',
    'Amount over due': 'Montant en retard',
    'Daily': 'Quotidien',
    'Monthly': 'Mensuel',
    'Yearly': 'Annuel',
    'Mobile': 'Mobile',
    'Usage': 'Utilisation',
    'Mobile Current Month Usage(GB)': 'Utilisation mobile du mois en cours (GB)',
    'StarLink Current Month Usage(GB)': 'Utilisation StarLink du mois en cours (GB)',
    'Serial': 'Numéro de série',
    'Simpools': 'Pools de SIM',
    'Telemetry': 'Télémétrie',
    'OOB': 'OOB',
    'Terminal ID': 'ID du terminal',
    'Antenna status': 'Statut de l\'antenne',
    'Downlink speed': 'Vitesse de téléchargement',
    'Uplink speed': 'Vitesse de téléversement',
    'Latency': 'Latence',
    'Uptime': 'Temps de fonctionnement',
    'State from OOBM': 'État de l\'OOBM',
    'Software update state': 'État de la mise à jour logicielle',
    'Unobstructed ratio': 'Ratio sans obstruction',
    'Antenna obstruction': 'Obstruction de l\'antenne',
    'Network statistics': 'Statistiques réseau',
    '15m': '15 min',
    '1 hr': '1 h',
    '24 hrs': '24 h',
    '7 days': '7 jours',
    '30 days': '30 jours',
    'Downlink throughput': 'Débit descendant',
    'Uplink throughput': 'Débit montant',
    'Ping drop rate': 'Taux de perte de ping',
    'Ping drop': 'Perte de ping',
    'Obstruction': 'Obstruction',
    'Stats export': 'Export des stats',
    'Never': 'Jamais',
    'Data Usage': 'Utilisation des données',
    'Settings': 'Paramètres',
    'Firstname': 'Prénom',
    'Lastname': 'Nom',
    'Password': 'Mot de passe',
    'KIT Serial': 'Numéro de série KIT',
    'Package Reference': 'Référence package',
    'Customer Reference': 'Référence client',
    'Services': 'Services',
    'Line': 'Ligne',
    'Custom': 'Personnalisé',
    'Package ID Reference': 'Référence ID package',
    'Draft': 'Brouillon',
    'Provisioning': 'Approvisionnement',
    'Deployed': 'Déployé',
    'Paused': 'En pause',
    'Ended': 'Terminé',
    'Shipping Address': 'Adresse de livraison',
    'Shipping Contact': 'Contact de livraison',
    'Sold Hardware': 'Matériel vendu',
    'DRAFT': 'BROUILLON',
    'PROVISIONING': 'APPROVISIONNEMENT',
    'DELIVERED': 'LIVRÉ',
    'ENDING': 'EN COURS DE TERMINAISON',
    'ENDED': 'TERMINÉ',
    'Running': 'En marche',
    'LINE': 'LIGNE',
    'Price': 'Prix',
    'Billing address': 'Adresse de facturation',
    'Starlink Usage reports': 'Rapports d\'utilisation Starlink',
    '%{length} of %{total} devices': '%{length} sur %{total} appareils'
};

export default fr;