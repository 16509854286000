import React from 'react';
import { TabbedShowLayout, Tab, TextField, ReferenceField, ReferenceArrayField, ReferenceManyField, Pagination, useTranslate } from 'react-admin';
import { Helmet } from 'react-helmet';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Show from '../common/Show';
import DateField from '../common/fields/DateField';
import ServiceGrid from '../services/ServiceGrid';
import StatusField from './StatusField';
import PackageLineGrid from '../package_lines/PackageLineGrid';

const PackageTitle = ({ record }) => (
	<>
		<Helmet>
			<title>{`KB - Package[${record.reference}]`}</title>
		</Helmet>
		<span>{record.reference}</span>
	</>
);

const ServiceList = ({ record }) => {
	const translate = useTranslate();
	let list = [];

	if (record.services_by_type?.sdwan) {
		list.push(
			<Box mb={5} key="services_sdwan">
				<Typography variant="h6">SDWan</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.sdwan">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.data) {
		list.push(
			<Box mb={5} key="services_data">
				<Typography variant="h6">Data</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.data">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.line) {
		list.push(
			<Box mb={5} key="services_line">
				<Typography variant="h6">{translate('Line')}</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.line">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.starlink) {
		list.push(
			<Box mb={5} key="services_starlink">
				<Typography variant="h6">StarLink</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.starlink">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	if (record.services_by_type?.custom) {
		list.push(
			<Box mb={5} key="services_custom">
				<Typography variant="h6">{translate('Custom')}</Typography>
				<ReferenceArrayField addLabel={false} reference="services" record={record} source="services_by_type.custom">
					<ServiceGrid />
				</ReferenceArrayField>
			</Box>
		);
	}
	return (
		<Box mt={5}>
			{list}
		</Box>
	);
};


const PackageShow = (props) => {
	const translate = useTranslate();

	return (
		<Show title={<PackageTitle />} {...props}>
			<TabbedShowLayout>
				<Tab label="summary">
					<TextField source="reference" label="Package ID Reference" />
					<TextField source="customer_reference" component="pre" />
					<ReferenceField label="Site" source="site_id" reference="sites" link="show" allowEmpty>
						<TextField source="name" />
					</ReferenceField>
					<StatusField />
					<TextField source="invoice_regroupment_reference" />
					<ReferenceField label="Data Pool" source="data_pool_id" reference="data_pools" link="show" allowEmpty>
						<TextField source="reference" />
					</ReferenceField>
					<DateField source="start_date" />
					<DateField source="end_date" />
					<TextField label="Shipping Address" source="shipping_address" component="pre" />
					<TextField label="Shipping Contact" source="shipping_contact" component="pre" />
					<ServiceList />
					<Typography variant="h5">{translate('Sold Hardware')}</Typography>
					<ReferenceManyField addLabel={false} reference="package_lines" target="package_id" pagination={<Pagination />} filter={{ archived: false }}>
						<PackageLineGrid exclude={['package']} />
					</ReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

export default PackageShow;
