import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

import variables from '../../style/variables';

const useStyles = makeStyles(theme => ({
	buttonGroup: {
		color: theme.palette.grey[600],
		'& a, & button': {
			borderColor: variables.colors.borderButton,
			borderRadius: '10px',
			minWidth: '40px'
		},
		'& a:first-child': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			'& span:first-child': {
				paddingTop: '3px',
				paddingBottom: '3px'
			}
		}
	},
	popper: {
		zIndex: 999
	},
	menuItem: {
		width: 'auto',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		padding: 0,
		minHeight: 'auto',

		'& > a, & button': {
			padding: '6px 16px',
			width: '100%',
			justifyContent: 'flex-start'
		}
	}
}));

const hasParentClass = (element, classnames) => {
	if (
		(typeof element.className == 'string' && element.className && element.className.split(' ').some(elementClass => classnames.some(className => elementClass.includes(className))))
		|| element.getAttribute('role') === 'tooltip'
	) {
		return true;
	}
	return (element.parentNode && element.parentNode.tagName !== 'BODY') && hasParentClass(element.parentNode, classnames);
};

const SplitButton = ({ children, color, size, closeOnButtonClick, ...props }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const classes = useStyles();
	const filteredChildren = React.Children.toArray(children, child => child).filter(child => child);

	const handleClick = (e) => {
		e.stopPropagation();
	};

	const handleMenuItemClick = (e) => {
		handleClick(e);

		// FIXME disabled for modal, workaround to fix some  
		if (closeOnButtonClick) {
			setOpen(false);
		}
	};

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = e => {
		// TODO: maybe find another solution later
		if ((anchorRef.current && anchorRef.current.contains(e.target)) || hasParentClass(e.target, ['MuiDialog', 'RaAutocompleteInput'])) {
			e.stopPropagation();
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup variant="outlined" color={color} size={size} ref={anchorRef} aria-label="split button" className={classes.buttonGroup} {...props} onClick={handleClick}>
				{React.cloneElement(filteredChildren[0], { label: "", className: classes.firstButton })}
				<Button
					color={color}
					size={size}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<List id="split-button-menu">
									{React.Children.map(filteredChildren, (child) => (
										child && <ListItem onClick={handleMenuItemClick} className={classes.menuItem}>{child}</ListItem>
									))}
								</List>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

SplitButton.defaultProps = {
	color: "inherit",
	size: "small"
};

SplitButton.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string
};

export default SplitButton;