import React, { useRef, useEffect, useState } from 'react';
import { List, Filter, Link, useTranslate } from 'react-admin';
import { Marker, InfoWindow } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Map from '../common/Map';
import SiteReferenceInput from '../sites/SiteReferenceInput';

import { getDeviceColors } from './deviceColors';

const useStyles = makeStyles({
	container: {
		marginTop: '30px',

		'& div:focus': {
			outline: 0
		}
	},
	count: {
		margin: 0,
		padding: '15px'
	},
	map: {
		'&&': {
			borderRadius: '12px',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0
		}
	}
});

const DeviceFilter = (props) => (
	<Filter {...props} variant="outlined">
		<SiteReferenceInput alwaysOn />
	</Filter>
);

const CustomMarker = ({ data, ...props }) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggleTooltip = () => {
		setTooltipOpen(!tooltipOpen);
	};

	const closeTooltip = () => {
		setTooltipOpen(false);
	};

	if (!data.lat || !data.lng) {
		return null;
	}

	return (
		<Marker
			position={{ lat: data.lat, lng: data.lng }}
			icon={{
				path: "M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
				fillColor: getDeviceColors(data.online_status, 300),
				fillOpacity: 1,
				strokeWeight: 1,
				strokeColor: '#FFF',
				scale: 2,
			}}
			onClick={toggleTooltip}
			{...props}
		>
			{tooltipOpen && (
				<InfoWindow onCloseClick={closeTooltip}>
					<>
						<Typography><b>{data.name}</b></Typography>
						<Typography>{data.serial}</Typography>
						<Typography>{data.product_name}</Typography>
						{data.site && <Typography>Site: <Link to={`/sites/${data.site.id}/show`}>{data.site.name}</Link></Typography>}
					</>
				</InfoWindow>
			)}
		</Marker>
	);
};

const CustomMap = ({ ids, data, total, basePath, classes, ...props }) => {
	const mapRef = useRef(null);
	const translate = useTranslate();

	useEffect(() => {
		if (mapRef.current) {
			fitBounds();
		}
	}, [mapRef, ids]); // eslint-disable-line react-hooks/exhaustive-deps

	const fitBounds = () => {
		if (ids.length > 0) {
			const bounds = new window.google.maps.LatLngBounds();
			ids.forEach(id => {
				if (data[id].lat && data[id].lng) {
					bounds.extend({ lat: data[id].lat, lng: data[id].lng });
				}
			});
			mapRef.current.fitBounds(bounds);
		}
	};

	return (
		<>
			<Map {...props} ref={mapRef} mapElement={<div style={{ height: `100%` }} className={`map ${classes.map}`} />}>
				<MarkerClusterer>
					{ids.map(id => (
						<CustomMarker key={`marker_${id}`} data={data[id]} />
					))}
				</MarkerClusterer>
			</Map>
			<p className={classes.count}>{translate('%{length} of %{total} devices', { length: ids.length, total })}</p>
		</>
	);
};

const MapDeviceList = ({ staticContext, ...props }) => {
	const classes = useStyles();

	return (
		<List {...props} className={classes.container}>
			<CustomMap classes={classes} />
		</List>
	);
};

MapDeviceList.defaultProps = {
	title: "Map",
	resource: "devices",
	basePath: "devices/map",
	hasList: true,
	hasShow: false,
	hasCreate: false,
	hasEdit: false,
	filters: <DeviceFilter />,
	filter: { map: true, in_stock: false, page_size: 300 },
	exporter: false,
	pagination: null,
};

export default MapDeviceList;