import React from 'react';
import { Filter as RAFilter } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	form: {
		marginTop: 0,
		
		'& > div': {
			alignItems: 'center'
		}
	}
});

const Filter = props => {
	const classes = useStyles();

	return (
		<RAFilter classes={classes} {...props} />
	);
};

Filter.defaultProps = {
	variant: "outlined"
};

export default Filter;