import React from 'react';

import Badge from '../Badge';

const BadgeField = ({ record, source, ...props }) => {
	if (!record && !source) {
		return null;
	}

	return (
		<Badge badgeContent={record[source]} color="grey" {...props} />
	);
};

export default BadgeField;